import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ClientLayout from "../../components/layout";
import Home from "../../views/home";
import AboutUs from "../../views/about";
import BlogsPage from "../../views/blogs";
import BlogDetails from "../../views/blogs/blogDetails";
import Login from "../../views/login";
import CreateAccount from "../../views/createAccount";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import ContactUs from "../../views/contactus";
import Events from "../../views/events";
import TicketsDetails from "../../views/ticketsDetails";
import OrderDetails from "../../views/orderDetails";
import TicketIssue from "../../views/ticketIssue";
import Payment from "../../views/Payment";
import ProtectedRoute from "../../views/protectedRoute";
import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import Organize from "../../views/organize/index";

function MyRouter() {
  return (
    <>
      {/* <BrowserRouter basename='/badshah_tickets'> */}
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <ClientLayout header={true}>
                <Home />
              </ClientLayout>
            }
          />
          <Route
            path="/organize"
            index
            element={

              <ClientLayout header={true}>
                <Organize />
              </ClientLayout>
            }
          />
           <Route
          path="/profile"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
            header={true}
            head={{ title: "Dashboard", description: "Some Description." }}
            headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Profile />
            </ClientLayout>
              </ProtectedRoute>
          }
          />
          <Route
          path="/editProfile"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              header={true}
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <EditProfile />
            </ClientLayout>
              </ProtectedRoute>
          }
          />
        <Route
          path="/changePassword"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              header={true}
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <ChangePassword />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
          <Route
            path="/about"
            index
            element={
              <ClientLayout header={true}>
                <AboutUs />
              </ClientLayout>
            }
          />
          <Route
            path="/blog"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <BlogsPage />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/blogDetails/:id"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <BlogDetails />
                </ClientLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/createAccount"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <CreateAccount />
              </ClientLayout>
            }
          />
          <Route
            path="/login"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <Login />
              </ClientLayout>
            }
          />
          <Route
            path="/forget-password"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <ForgetPassword1 />
              </ClientLayout>
            }
          />
          <Route
            path="/enter-email"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <ForgetPassword2 />
              </ClientLayout>
            }
          />
          <Route
            path="/code"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <ForgetPassword3 />
              </ClientLayout>
            }
          />

          <Route
            path="/contactus"
            index
            element={
              <ClientLayout header={true}>
                <ContactUs />
              </ClientLayout>
            }
          />
          <Route
            path="/events"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <Events />
                </ClientLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/event-details/:id"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <TicketsDetails />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/order_details/:id"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <OrderDetails />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/ticket-issue"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <TicketIssue />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment"
            index
            element={
              <ProtectedRoute>
                <ClientLayout
                  head={{
                    title: "Dashboard",
                    description: "Some Description.",
                  }}
                  headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                  <Payment />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MyRouter;
