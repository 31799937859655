import React, { useState } from "react";
import { Col, Row, Button, Form,Image, Input, Space } from "antd";
import { useNavigate, Link  } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import { ImageUrl } from "../../config/helper";
import { Post } from "../../config/api/post";
import { NEWSLETTER } from "../../config/constants/api";
import swal from "sweetalert";
import { useSelector } from "react-redux";

function Footer() {
  const [form] = Form.useForm();
  const user = useSelector((state) => state.user.userData);

  const onFinish = (values) => {
    Post(NEWSLETTER.signup, { email: values?.email, user: user?._id })
      .then((response) => {
        if (response?.status) {
          swal("System Alert!", response.message, "success");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  const onFinishFailed = () =>{
    console.log('failed')
  }
  return (
    <>
      <div
        className="footer wow fadeInDown"
        data-wow-duration="2.5s"
        data-wow-delay="0.8s"
      >
        <Row justify="center">
          <Col xs={22} lg={20}>
            <Row>
              <Col xs={24}>
                <h3>20% OFF ON YOUR FIRST BOOKING</h3>
                <h2>NEWSLETTER SIGNUP</h2>
                <Form
                  className="row g-3"
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  // onFinish={onFinish}
                  autoComplete="off"
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed = {onFinishFailed}
                >
                  <Form.Item
                    label=""
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Type your email here...",
                      },
                    ]}
                  >
                     <Space.Compact
                  style={{
                    width: "100%",
                    borderRadius: 0,
                    marginBottom: "20px",
                    maxWidth: "800px",
                  }}
                >
                  <Input
                    size="large"
                    placeholder="Type your email here..."
                    className="web-input"
                  />
                  <Button htmlType="submit" type="primary" className="footerbtn">
                    <MdArrowOutward />
                  </Button>
                </Space.Compact>
                  </Form.Item>
                </Form>
               
                <p>
                  Sign up to receive the latest news from us, including new
                  events, exclusive discounts, early access to sales and more.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <ul style={{listStyle:"none"}}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contactus">Contact us</Link>
                  </li>
                  <li>
                    <Link>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link>Terms & Conditions</Link>
                  </li>
                </ul>
              </Col>

              <Col xs={24}>
                <Link className="socialicon">
                  <Image preview={false} src={ImageUrl("fb-icon.png")} />
                </Link>
                <Link className="socialicon">
                  <Image preview={false} src={ImageUrl("insta-icon.png")} />
                </Link>
                <Link className="socialicon">
                  <Image preview={false} src={ImageUrl("snapchat-icon.png")} />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="bottom">
        <Row align={"middle"} justify={"center"}>
          <Col xs={22}>
            <p>Copyright@2024 Badshah EVENTS . All Right Reserved</p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Footer;
