import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import { Post } from "../../config/api/post";
import { BOOKING, PAYMENT } from "../../config/constants/api";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import payment from '../Payment/payment.css';
export default function CheckoutForm({ amount, quantity, eventDetails }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const afterPayment = () => {
    let data = {
      event: eventDetails?._id,
      totalAmount: amount,
      quantity,
    };
    Post(BOOKING.bookEventTicket, data, token)
      .then((response) => {
        if (response?.status) {
          swal("System Alert!", response.message, "success");
          navigate("/ticket-issue", {
            state: { eventDetails, booking: response?.data?.booking , qrCode : response?.data?.qrCode },
          });
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  const savePayment = async (paymentIntent) => {
    try {
      const data = {
        paymentIntentId: paymentIntent.id,
        amount,
        currency: "USD",
        event: eventDetails?._id,
        // description: "Payment for buying subscription plan",
      };

      const response = await Post(PAYMENT.savePayment, data, token);
      if (response.status) {
        afterPayment();
      } else {
        setMessage("Payment succeeded, but updating subscription failed.");
      }
    } catch (err) {
      console.error(err);
      setMessage("An unexpected error occurred.");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/`,
      },
      redirect: "if_required",
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment Status: " + paymentIntent.status + " 🎉");
      savePayment(paymentIntent);
      // navigate('/')
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <div className="payment-parent">
    <form id="payment-form" onSubmit={handleSubmit} >
      <PaymentElement id="payment-element" />
      <button
        className="mainbtn"
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    </div>
  );
}
