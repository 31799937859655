import React, { useState, useEffect } from "react";
import { Col, Row, Spin, Pagination } from "antd";
import BlogCards from "../../components/blogCard";
import { BLOG } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { Get } from "../../config/api/get";
function BlogsPage() {
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.userData);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = blogs
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";
  const token = useSelector((state) => state.user.userToken);
  const getBlogs = (pageNumber, pageSize) => {
    setLoading(true);
    Get(BLOG.getAllBlogs, token, {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
    })
      .then((response) => {
        if (response?.status) {
          setBlogs(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getBlogs();
  }, []);
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getBlogs(pageNumber, paginationConfig.limit);
  };
  return (
    <div className="contentarea inner-page">
      <Row justify={"center"}>
        <Col xs={22} lg={20}>
          <div className="blog">
            <Row justify={"center"}>
              <Col xs={24} lg={24}>
                <h2 className="text-center">BLOG POST</h2>

                {!loading ? (
                  <>
                  <Row justify={"center"} gutter={16}>
                    {Array.isArray(blogs) &&
                      blogs?.length > 0 &&
                      blogs.map((item, index) => {
                        return (
                          <Col xs={22} md={12} lg={6}>
                            <BlogCards item={item} />{" "}
                          </Col>
                        );
                      })}
                  </Row>
                   <Row style={{ padding: "10px 20px" }}>
                   <Col xs={24} md={12}>
                     <p style={{color:"white"}}>{message}</p>
                   
                   </Col>
                   <Col
                     xs={24}
                     md={12}
                     style={{
                       display: "flex",
                       justifyContent: "flex-end",
                     }}
                   >
                     <Pagination
                       className="styledPagination"
                       onChange={(e) => handlePageChange(e)}
                       current={parseInt(paginationConfig?.pageNumber)}
                       pageSize={paginationConfig?.limit}
                       total={paginationConfig?.totalDocs}
                       itemRender={itemRender}
                     />
                   </Col>
                 </Row>
                 </>
                ) : (
                  <Row
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Col
                      lg={19}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "150px 0px",
                      }}
                    >
                      <Spin />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BlogsPage;
