import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Radio,
  Button,
  Space,
  Collapse,
  Card,
  List,
  Image,
  Drawer,
  Rate,
  Select,
  Typography,
  DatePicker,
  Badge,
  Checkbox,
  Slider,
  Pagination,
  Avatar,
  Spin,
} from "antd";
import { useNavigate } from "react-router";
import { MdMenu } from "react-icons/md";
import EventCard from "../../../components/eventCard";
import RangeSlider from "../../../components/rangeSlider";
import CaregeorySlider from "../../../components/categeorySlider";
import AliceCarousel from "react-alice-carousel";
import { ImageUrl } from "../../../config/helper";

import { CaretRightOutlined } from "@ant-design/icons";
import { EVENT } from "../../../config/constants/api";
import { useSelector } from "react-redux";
import { Get } from "../../../config/api/get";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
// import { bookingDate } from "../../../components/Data/data";

const ServicePage = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const [categories, setCategories] = useState([
    "Completed",
    "Ongoing",
    "Upcomming",
  ]);
  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState([0, 10000]);
  const handleLocChange = (newAddress) => {
    setAddress(newAddress);
  };
  const handleSelect = (newAddress) => {
    setAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLatitude(latLng?.lat);
        setLongitude(latLng?.lng);
      })
      .catch((error) => console.error("Error", error));
  };
  const handleSearch = () => {
    getEvents(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      typeState,
      eventDate,
      range[0],
      range[1],
      latitude,
      longitude
    );
  };
  const handleClear = () => {
    getEvents(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      typeState,
      eventDate,
      range[0],
      range[1]
    );
    setAddress("");
  };
  const onRangeChange = (value) => {
    setRange(value);
    getEvents(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      typeState,
      eventDate,
      value[0],
      value[1]
    );
  };
  const user = useSelector((state) => state.user.userData);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const [typeState, setTypeState] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = events
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";
  const token = useSelector((state) => state.user.userToken);
  const getEvents = (
    pageNumber,
    pageSize,
    type,
    date,
    minPrice,
    maxPrice,
    log,
    lat
  ) => {
    setLoading(true);
    Get(EVENT.getAllEvents, token, {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      type: type !== "All" ? type : null,
      date: date,
      minPrice,
      maxPrice,
      log,
      lat,
    })
      .then((response) => {
        if (response?.status) {
          setEvents(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getEvents();
  }, []);
  const handleCategorySelect = (type) => {
    if (type === "All") {
      if (!(typeState === "All" || typeState === "")) {
        setTypeState(type);
        getEvents(
          paginationConfig?.pageNumber,
          paginationConfig?.limit,
          null,
          eventDate,
          range[0],
          range[1],
          latitude,
          longitude
        );
      }
    } else {
      getEvents(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        type,
        eventDate,
        range[0],
        range[1],
        latitude,
        longitude
      );
      setTypeState(type);
    }
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getEvents(
      pageNumber,
      paginationConfig.limit,
      typeState,
      eventDate,
      range[0],
      range[1],
      latitude,
      longitude
    );
  };
  const responsive = {
    380: { items: 2 },
    568: { items: 2 },
    1024: { items: 3 },
    1200: { items: 4 },
    1400: { items: 4 },
    1500: { items: 5 },
  };
  const items = [
    <div className="item">
      <div
        className="topCategeorySlider"
        onClick={() => {
          handleCategorySelect("All");
        }}
      >
        <Image preview={false} src={ImageUrl("all-icon.png")} />
        <span>All</span>
      </div>
    </div>,
    <div className="item">
      <div
        className="topCategeorySlider"
        onClick={() => {
          handleCategorySelect("Attractions");
        }}
      >
        <Image preview={false} src={ImageUrl("attractions-icon.png")} />
        <span>Attractions</span>
      </div>
    </div>,
    <div className="item">
      <div
        className="topCategeorySlider"
        onClick={() => {
          handleCategorySelect("Playground");
        }}
      >
        <Image preview={false} src={ImageUrl("playground-icon.png")} />
        <span>Playground</span>
      </div>
    </div>,
    <div className="item">
      <div
        className="topCategeorySlider"
        onClick={() => {
          handleCategorySelect("Entertainment");
        }}
      >
        <Image preview={false} src={ImageUrl("entertainment.png")} />
        <span>Entertainment</span>
      </div>
    </div>,
    // <div className="item">
    //   <div className="topCategeorySlider" onClick={()=>{handleCategorySelect()}}>
    //     <Image preview={false} src={ImageUrl("events.png")} />
    //     <span>Events</span>
    //   </div>
    // </div>,
  ];

  const [value, setValue] = useState(0);
  const onChange = (e) => {
    setValue(e.target.value);
    let date = new Date();
    if (e.target.value == 1) {
      date = moment(date).format("YYYY-MM-DD");
    } else {
      date.setDate(date.getDate() + 1);
      date = moment(date).format("YYYY-MM-DD");
    }
    setEventDate(date);
    getEvents(
      paginationConfig?.pageNumber,
      paginationConfig?.limit,
      typeState,
      date,
      range[0],
      range[1],
      latitude,
      longitude
    );
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const { Option } = Select;
  // const [eventCard, SetEventCard] = useState([
  //   {
  //     key: 1,
  //     image: "blog-5.png",
  //     title: "Dussehra  Tickets",
  //     location: "Gianna",
  //     rating: "8.0 (1542 Review)",
  //     priceCut: "$140.00",
  //     price: "$110.00",
  //   },

  //   {
  //     key: 2,
  //     image: "blog-6.png",
  //     title: "COMEDY NIGHT",
  //     location: "Bogor Ana",
  //     rating: "4.2 (516 Review)",
  //     priceCut: "$70.00",
  //     price: "$50.00",
  //   },
  //   {
  //     key: 3,
  //     image: "blog-7.png",
  //     title: "COMEDY NIGHT",
  //     location: "Bogor Ana",
  //     rating: "4.2 (516 Review)",
  //     priceCut: "$70.00",
  //     price: "$50.00",
  //   },
  //   {
  //     key: 4,
  //     image: "blog-8.png",
  //     title: "COMEDY NIGHT",
  //     location: "Bogor Ana",
  //     rating: "4.2 (516 Review)",
  //     priceCut: "$70.00",
  //     price: "$50.00",
  //   },
  //   {
  //     key: 5,
  //     image: "blog-1.png",
  //     title: "COMEDY NIGHT",
  //     location: "Bogor Ana",
  //     rating: "4.2 (516 Review)",
  //     priceCut: "$70.00",
  //     price: "$50.00",
  //   },

  //   {
  //     key: 6,
  //     image: "blog-2.png",
  //     title: "COMEDY NIGHT",
  //     location: "Bogor Ana",
  //     rating: "4.2 (516 Review)",
  //     priceCut: "$70.00",
  //     price: "$50.00",
  //   },
  //   {
  //     key: 7,
  //     image: "blog-3.png",
  //     title: "COMEDY NIGHT",
  //     location: "Bogor Ana",
  //     rating: "4.2 (516 Review)",
  //     priceCut: "$70.00",
  //     price: "$50.00",
  //   },
  //   {
  //     key: 8,
  //     image: "blog-4.png",
  //     title: "COMEDY NIGHT",
  //     location: "Bogor Ana",
  //     rating: "4.2 (516 Review)",
  //     priceCut: "$70.00",
  //     price: "$50.00",
  //   },
  // ]);
  const onChangeDate = (date, dateString) => {
    setEventDate(moment(date.$d).format("YYYY-MM-DD"));
    getEvents(
      paginationConfig?.pageNumber,
      paginationConfig?.limit,
      typeState,
      moment(date.$d).format("YYYY-MM-DD"),
      range[0],
      range[1],
      latitude,
      longitude
    );
  };

  return (
    <div className="shop-page  contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={0} md={10} lg={7} xl={6}>
                <div className="left-div">
                  <div className="filter-heading">
                    <p>Filters</p>
                  </div>
                  <div className="inner-filter-box filter-canvas">
                    <Form layout="vertical">
                      <Form.Item
                        direction="vertical"
                        // label="Ticket Availability"
                      >
                        <Radio.Group onChange={onChange} value={value}>
                          <Radio value={1}>Today</Radio>
                          <Radio value={2}>Tomorrow</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="Price Range">
                        {/* <RangeSlider /> */}
                        <div>
                          <Slider
                            range
                            min={0}
                            max={10000}
                            step={100}
                            defaultValue={range}
                            onChange={onRangeChange}
                            trackStyle={[{ backgroundColor: "#8000FF" }]}
                            handleStyle={[
                              { borderColor: "#8000FF" },
                              { borderColor: "#8000FF" },
                            ]}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#fff",
                            }}
                          >
                            <span>${range[0]}</span>
                            <span>${range[1]}</span>
                          </div>
                        </div>
                      </Form.Item>

                      <Form.Item label="Date">
                        <DatePicker
                          className="web-input2"
                          onChange={onChangeDate}
                        />
                      </Form.Item>

                      <Form.Item label="LOCATION">
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleLocChange}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => {
                            return (
                              <div className="location-drop">
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Event By Location....",
                                    style: {
                                      marginBottom: "10px",
                                      height: "52px",
                                      borderRadius: "0px",
                                      border: "none",
                                      borderBottom: "2px solid white",
                                      background: "none",
                                      padding: "10px 50px 10px 10px",
                                    },
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active googleSuggestion"
                                      : "suggestion-item googleSuggestion";
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                          padding: "5px 10px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                          padding: "5px 10px",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        </PlacesAutocomplete>
                      </Form.Item>
                    </Form>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="apply-btn">
                        <Button
                          style={{
                            cursor: "pointer",
                          }}
                          className="mainbtn"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </div>

                      <div className="clear-btn">
                        <Button
                          style={{
                            cursor: "pointer",
                          }}
                          className="mainbtn"
                          onClick={handleClear}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} md={0} lg={0} xl={0}>
                <>
                  <MdMenu
                    style={{ fontSize: 26, color: "#fff" }}
                    onClick={showDrawer}
                  />
                  <Drawer
                    // title="Basic Drawer"
                    placement="right"
                    onClose={onClose}
                    open={open}
                    className="fliterMenu"
                  >
                    <div className="left-div">
                      <div className="filter-heading">
                        <p>Filters</p>
                      </div>
                      <div className="inner-filter-box filter-canvas">
                        <Form
                          layout="vertical"
                          name="basic"
                          initialValues={{
                            remember: true,
                          }}
                        >
                          <Form.Item
                            direction="vertical"
                            label="Ticket Availability"
                          >
                            <Radio.Group onChange={onChange} value={value}>
                              <Radio value={1}>Today</Radio>
                              <Radio value={2}>Tomorrow</Radio>
                            </Radio.Group>
                          </Form.Item>

                          <Form.Item label="Price Range">
                            {/* <RangeSlider /> */}
                            <div>
                              <Slider
                                range
                                min={0}
                                max={10000}
                                step={100}
                                defaultValue={range}
                                onChange={onRangeChange}
                                trackStyle={[{ backgroundColor: "#8000FF" }]}
                                handleStyle={[
                                  { borderColor: "#8000FF" },
                                  { borderColor: "#8000FF" },
                                ]}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  color: "#fff",
                                }}
                              >
                                <span>${range[0]}</span>
                                <span>${range[1]}</span>
                              </div>
                            </div>
                          </Form.Item>

                          <Form.Item label="Date">
                            <DatePicker
                              className="web-input2"
                              onChange={onChangeDate}
                            />
                          </Form.Item>

                          <Form.Item label="LOCATION">
                            <PlacesAutocomplete
                              value={address}
                              onChange={handleLocChange}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => {
                                return (
                                  <div className="location-drop">
                                    <input
                                      {...getInputProps({
                                        placeholder:
                                          "Search Event By Location....",
                                        style: {
                                          marginBottom: "10px",
                                          height: "52px",
                                          borderRadius: "100px",
                                          border: "1px solid #f4f4f4",
                                          backgroundColor: "#f4f4f4",
                                          padding: "10px 50px 10px 10px",
                                         
                                        },
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active googleSuggestion"
                                          : "suggestion-item googleSuggestion";
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                              padding: "5px 10px",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                              padding: "5px 10px",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }}
                            </PlacesAutocomplete>
                          </Form.Item>
                        </Form>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="apply-btn">
                            <Button
                              style={{
                                cursor: "pointer",
                              }}
                              className="mainbtn"
                              onClick={handleSearch}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="clear-btn">
                            <Button
                              style={{
                                cursor: "pointer",
                              }}
                              className="mainbtn"
                              onClick={handleClear}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Drawer>
                </>
              </Col>

              <Col xs={24} md={14} lg={17} xl={18}>
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    {/* <CaregeorySlider /> */}
                    <div className="categeorySlider">
                      <Row gutter={[16, 16]}>
                        <Col xs={24}>
                          <AliceCarousel
                            mouseTracking
                            items={items}
                            responsive={responsive}
                            disableButtonsControls={false}
                            disableDotsControls
                            controlsStrategy="alternate"
                            paddingLeft={0}
                            paddingRight={0}
                            infinite
                            autoPlay={false}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{
                          width: "100%",
                        }}
                      >
                        {!loading ? (
                          <>
                            <Row gutter={[16, 0]}>
                              {Array.isArray(events) && events.length > 0 ? (
                                events.map((item, index) => {
                                  return (
                                    <Col xs={22} sm={12} md={24} lg={8}>
                                      <EventCard item={item} />{" "}
                                    </Col>
                                  );
                                })
                              ) : (
                                <p
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    margin: "250px auto",
                                    fontSize: "22px",
                                  }}
                                >
                                  No Events to Show
                                </p>
                              )}
                            </Row>
                            <Row style={{ padding: "10px 20px" }}>
                              <Col xs={24} md={12}>
                                <p style={{ color: "white" }}>{message}</p>
                              </Col>
                              <Col
                                xs={24}
                                md={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Pagination
                                  className="styledPagination"
                                  onChange={(e) => handlePageChange(e)}
                                  current={parseInt(
                                    paginationConfig?.pageNumber
                                  )}
                                  pageSize={paginationConfig?.limit}
                                  total={paginationConfig?.totalDocs}
                                  itemRender={itemRender}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Row
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <Col
                              lg={19}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "150px 0px",
                              }}
                            >
                              <Spin />
                            </Col>
                          </Row>
                        )}
                      </Space>
                    </div>

                    {/* <Pagination defaultCurrent={1} total={50} /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServicePage;
