import React from 'react'
import AliceCarousel from "react-alice-carousel";
import { Col, Row, Image, Rate, Avatar } from "antd";
import { ImageUrl } from "../../config/helper";

const responsive = {
    380: { items: 1 },
    568: { items: 2 },
    1024: { items:3 },
    1200: { items:4 },
  };
  const items = [
    <div className="item wow fadeInDown" data-wow-duration="2.5s" data-wow-delay="0.8s" data-value="1">
      <div className="clientreview ">
      <Rate allowHalf defaultValue={4.5} />
      <h3>Great Service</h3>
      <p>Lorem Ipsum is simply dummy text of the
printing and typesetting industry. Lorem
Ipsum has been the industry's standard
dummy text ever. Lorem Ipsum is simply
Letterset sheets containing.</p>
      <Row gutter={20} className="margin-y-15">
        <Col>
          <Avatar
            size={48}
            icon={<Image preview={false} src={ImageUrl("logo.png")}  />}
          />
        </Col>
        <Col>
          <h4>James Carter</h4>
          <span>Oct 28 - 08 min ago</span>
        </Col>
      </Row>
      </div>
      
      
    </div>,
    <div className="item" data-value="1">
    <div className="clientreview ">
    <Rate allowHalf defaultValue={4.5} />
    <h3>Great Service</h3>
    <p>Lorem Ipsum is simply dummy text of the
printing and typesetting industry. Lorem
Ipsum has been the industry's standard
dummy text ever. Lorem Ipsum is simply
Letterset sheets containing.</p>
    <Row gutter={20} className="margin-y-15">
      <Col>
        <Avatar
          size={48}
          icon={<Image preview={false} src={ImageUrl("logo.png")}  />}
        />
      </Col>
      <Col>
        <h4>James Carter</h4>
        <span>Oct 28 - 08 min ago</span>
      </Col>
    </Row>
    </div>
    
    
  </div>,
  <div className="item" data-value="1">
      <div className="clientreview ">
      <Rate allowHalf defaultValue={4.5} />
      <h3>Great Service</h3>
      <p>Lorem Ipsum is simply dummy text of the
printing and typesetting industry. Lorem
Ipsum has been the industry's standard
dummy text ever. Lorem Ipsum is simply
Letterset sheets containing.</p>
      <Row gutter={20} className="margin-y-15">
        <Col>
          <Avatar
            size={48}
            icon={<Image preview={false} src={ImageUrl("logo.png")}  />}
          />
        </Col>
        <Col>
          <h4>James Carter</h4>
          <span>Oct 28 - 08 min ago</span>
        </Col>
      </Row>
      </div>
      
      
    </div>,
    <div className="item" data-value="1">
    <div className="clientreview ">
    <Rate allowHalf defaultValue={4.5} />
    <h3>Great Service</h3>
    <p>Lorem Ipsum is simply dummy text of the
printing and typesetting industry. Lorem
Ipsum has been the industry's standard
dummy text ever. Lorem Ipsum is simply
Letterset sheets containing.</p>
    <Row gutter={20} className="margin-y-15">
      <Col>
        <Avatar
          size={48}
          icon={<Image preview={false} src={ImageUrl("logo.png")}  />}
        />
      </Col>
      <Col>
        <h4>James Carter</h4>
        <span>Oct 28 - 08 min ago</span>
      </Col>
    </Row>
    </div>
    
    
  </div>,
  ];

function TesimonialsCaurosel() {
  return (
    <div>
         <div>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls
        controlsStrategy="alternate"
        paddingLeft={50}
        paddingRight={50}
        infinite
        autoPlay
        autoPlayInterval={800}
      />
    </div>
    </div>
  )
}

export default TesimonialsCaurosel