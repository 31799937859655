import { useEffect } from "react";
import { Col, Row, Button , Form , Input } from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { UPLOADS_URL } from "../../../config/constants/api";
// import DashbordSidebar from "../../../components/DashboardSidebar";
// import { myprofiledata } from "../../../components/Data/data";
import { extractDate } from "../../../config/helpers/index";
import { ImageUrl } from "../../../config/helper";

const DropzoneFiltercards = () => {
  const userData = useSelector((state) => state?.user?.userData);
  const navigate = useNavigate();


  return (
    // <div className="shop-page">
    //   <Row style={{ width: "100%", justifyContent: "center" }}>
    //     <Col xs={24} md={24}>
    //       <div className="shop-page-main">
    //         <Row>
    //           <Col xs={24} md={24} lg={24} xl={24}>
    //             <div className="my-account-profile">
    //               <section className="side-menu-parent">
    //                 {/* <DashbordSidebar /> */}
    //                 <div className="about-us-section">
    //                   <div className="profile-information-wrapper">
    //                     <h3 className="main-heading">Profile Information</h3>
    //                   </div>
    //                   <div className="bg-parent">
    //                     <Row
    //                       gutter={[16, 16]}
    //                       align={"middle"}
    //                       justify={"center"}
    //                     >
    //                       <Col md={14} lg={14} xl={14}>
    //                         <div className="">
    //                           <div className="logo-rectangle">
    //                             <div className="profile-info">
    //                             <div className="wrapper-group-1000001858" style={{width:"150px"}}>
    //                                   <img
    //                                     src={UPLOADS_URL + userData?.image}
    //                                     alt="event image"
    //                                     preview="false"
    //                                     style={{
    //                                       borderRadius: "100%",
    //                                       objectFit: "cover",
    //                                       objectPosition: "center",
    //                                       height:"150px",
    //                                       width:"150px"
    //                                     }}
    //                                   />
    //                                 </div>
    //                               <div className="full-name">
    //                                 <div className="jake-dawson">
    //                                   <div className="phone-number">
    //                                     <div className="full-name1">
    //                                       <p className="full-name2">
    //                                         First Name
    //                                       </p>
    //                                     </div>
    //                                     <div className="jake-dawson1">
    //                                       {userData?.firstName}
    //                                     </div>
    //                                   </div>
    //                                   <div className="gender">
    //                                     <div className="phone-number1">
    //                                       Email
    //                                     </div>
    //                                     <div className="frame-parent">
    //                                       <div className="a-b-c">
    //                                         {userData?.email}
    //                                       </div>
    //                                     </div>
    //                                   </div>
    //                                   {/* <div className="location">Location</div> */}
    //                                 </div>
    //                                 <div className="changepassword">
    //                                   <div className="b-g">
    //                                     <div className="email">Last Name</div>
    //                                     <div className="jakesamplecom">
    //                                       {userData?.lastName}
    //                                     </div>
    //                                   </div>
    //                                   <div className="b-g1">
    //                                     <div className="gender1">Phone</div>
    //                                     <div className="male">
    //                                       {userData?.phone}
    //                                     </div>
    //                                   </div>
    //                                 </div>
    //                               </div>
    //                               {/* <div className="abc-location-town">
    //                                   {userData?.location?.street +
    //                                     ", " +
    //                                     userData?.location?.state +
    //                                     ", " +
    //                                     userData?.location?.city +
    //                                     ", " +
    //                                     userData?.location?.country}
    //                                 </div> */}
    //                             </div>
    //                             {/* <div className="f-a-qs">
    //                                 <div className="career">
    //                                   <div className="date-of-birth">
    //                                     Date Of Birth
    //                                   </div>
    //                                 </div>
    //                                 <div className="termsconditions">
    //                                   <div className="jan-28-1998">
    //                                     {extractDate(
    //                                       userData?.dateOfBirth
    //                                     )}
    //                                   </div>
    //                                 </div>
    //                               </div> */}
    //                             <div className="frame-group" style={{margin:'0px auto'}}>
    //                               <div className="">
    //                                 <Button
    //                                   type=""
    //                                   block
    //                                   size={"large"}
    //                                   style={{ marginBottom: "10px" }}
    //                                   className="web-btn"
    //                                   onClick={() => navigate("/editProfile")}
    //                                 >
    //                                   Edit Profile
    //                                 </Button>
    //                               </div>
    //                               <div className="">
    //                                 <Button
    //                                   type=""
    //                                   block
    //                                   size={"large"}
    //                                   style={{ marginBottom: "10px" }}
    //                                   className="web-btn"
    //                                   onClick={() =>
    //                                     navigate("/changePassword")
    //                                   }
    //                                 >
    //                                   Change password
    //                                 </Button>
    //                               </div>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </Col>
    //                     </Row>
    //                   </div>
    //                 </div>
    //               </section>
    //             </div>
    //           </Col>
    //         </Row>
    //       </div>
    //     </Col>
    //   </Row>
    // </div>
    <div className="shop-page contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={22} md={22}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <h5 className="text-center">Profile Information</h5>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={22} lg={22}>
                  <div className="details-card my-4">
                    <Row gutter={[16, 16]}>
                    <Col md={24} lg={24} xl={24}>
                            <div className="">
                              <div className="logo-rectangle">
                                <div className="profile-info">
                                <div className="wrapper-group-1000001858" style={{width:"150px"}}>
                                      <img
                                        src={UPLOADS_URL + userData?.image}
                                        alt="event image"
                                        preview="false"
                                        style={{
                                          borderRadius: "100%",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                          height:"150px",
                                          width:"150px"
                                        }}
                                      />
                                    </div>
                                    <div className="full-name">
                                    <div className="jake-dawson">
                                      <div className="phone-number">
                                        <div className="full-name1">
                                          <p className="full-name2">
                                            First Name
                                          </p>
                                        </div>
                                        <div className="jake-dawson1">
                                          {userData?.firstName}
                                        </div>
                                      </div>
                                      <div className="gender">
                                        <div className="phone-number1">
                                          Email
                                        </div>
                                        <div className="frame-parent">
                                          <div className="a-b-c">
                                            {userData?.email}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="location">Location</div> */}
                                    </div>
                                    
                                    <div className="full-name">
                                    <div className="jake-dawson">
                                      <div className="phone-number">
                                        <div className="full-name1">
                                          <p className="full-name2">
                                            Last Name
                                          </p>
                                        </div>
                                        <div className="jake-dawson1">
                                          {userData?.lastName}
                                        </div>
                                      </div>
                                      <div className="gender">
                                        <div className="phone-number1">
                                          Phone
                                        </div>
                                        <div className="frame-parent">
                                          <div className="a-b-c">
                                            {userData?.phone}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="location">Location</div> */}
                                    </div>
                                    </div>
                                  </div>
                                  <div className="frame-group" style={{margin:'0px auto'}}>
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{ marginBottom: "10px" , justifyContent:'center' }}
                                      className="mainbtn"
                                      onClick={() => navigate("/editProfile")}
                                    >
                                      Edit Profile
                                    </Button>
                                  </div>
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{ marginBottom: "10px" , justifyContent:'center'}}
                                      className="mainbtn text-center"
                                      onClick={() =>
                                        navigate("/changePassword")
                                      }
                                    >
                                      Change password
                                    </Button>
                                  </div>
                                </div>
                                </div>
                             
                                
                              </div>
                            </div>
                          </Col>

                      
                      {/* <Col xl={9} lg={10}>
                        <div className="help-frame">
                          <div>
                            <h6>Contact Information</h6>
                            <span>ABC Company Name</span>
                            <p>
                              <FaPhoneVolume /> email@email.com
                            </p>
                            <p>
                              <MdEmail /> demo@gmail.com
                            </p>
                            <p>
                              <FaLocationDot /> 132 Dartmouth Street, Boston,
                              Massachusetts 02156, United States
                            </p>
                          </div>
                          <div className="social-icons">
                            <Link className="socialicon">
                              <Image
                                preview={false}
                                src={ImageUrl("fb-icon.png")}
                              />
                            </Link>
                            <Link className="socialicon">
                              <Image
                                preview={false}
                                src={ImageUrl("insta-icon.png")}
                              />
                            </Link>
                            <Link className="socialicon">
                              <Image
                                preview={false}
                                src={ImageUrl("snapchat-icon.png")}
                              />
                            </Link>
                          </div>
                        </div>
                      </Col> */}
                      {/* <Col xl={15} lg={14}>
                        <Form
                          className="row g-3"
                          name="basic"
                          layout="vertical"
                          initialValues={{
                            remember: true,
                          }}
                          // onFinish={onFinish}
                          autoComplete="off"
                          form={form}
                          onFinish={onFinish}
                        >
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your First Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter First Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Last Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Last Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Email!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Email address"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Subject!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Subject"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Message"
                                name="message"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Message!",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Write your message.."
                                  className="web-textarea"
                                />
                              </Form.Item>
                            </Col>
                            <div className="" style={{ textAlign: "center" }}>
                              <Button
                                type=""
                                htmlType="submit"
                                className="mainbtn"
                              > 
                                {loading ? "Loading..." : "Send Feedback"}
                                <BsArrowUpRightCircleFill />
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </Col> */}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
