import { Row, Col, Image, Button } from "antd";
import React from "react";

import { ImageUrl } from "../../config/helper";
import { FaCheckCircle } from "react-icons/fa";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();
  return (
    <div
      className="banner  wow fadeInDown"
      data-wow-duration="2.5s"
      data-wow-delay="0.8s"
    >
      <Row justify="center">
        <Col xs={22} lg={20}>
          <Row align="bottom">
            <Col xs={24} md={12}>
              <h3>
                Book your
                <br />
                tickets
              </h3>
              <h4>For events!</h4>
              <p>
                Welcome to Event Mela, your ultimate destination for discovering
                and enjoying events with ease. Our platform empowers organizers
                to create and manage their events seamlessly while providing
                users with a simple and efficient way to purchase tickets in
                just one click.
           </p>
           {/* <p>
                At Event Mela, we are committed to making event management and
                attendance hassle-free. Our user-friendly interface ensures a
                smooth experience for both organizers and attendees, making
                every event memorable and accessible. Join us and be part of the
                Event Mela community, where every event is just a click away.
              </p> */}
              <ul>
                <li>
                  <FaCheckCircle /> Your ticket to live entertainment!
                </li>
                <li>
                  <FaCheckCircle /> Safe, Secure, Reliable Ticketing.
                </li>
              </ul>
              <Button
                className="mainbtn"
                onClick={() => {
                  navigate("/events");
                }}
              >
                Buy Ticket Now <BsArrowUpRightCircleFill />
              </Button>
            </Col>
            <Col xs={24} md={12}>
              <Image
                preview={false}
                src={ImageUrl("banner-man.png")}
                alt="Banner"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Banner;
