import React, { useEffect, useState } from "react";
import { Layout, Col, Row, Button, Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import VerificationInput from "react-verification-input";
import { Post } from "../../config/api/post";
import { RESET } from "../../config/constants/api";
import { FaArrowLeftLong } from "react-icons/fa6";

function ForgetPassword2() {
  const location = useLocation();
  const email = location?.state?.email;
  const token = useSelector((state) => state.user.userToken);
  useEffect(() => {
    if (!email) {
      navigate("/forget-password-1");
    }
  }, []);
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const handleSubmit = () => {
    Post(RESET.verifyCode, { email: email, code: code, type: "USER" }, token)
      .then((response) => {
        if (response?.status) {
          swal("Success", response?.message, "success");
        }
        navigate("/code", { state: { email: email, code: code } });
      })
      .catch((err) => {
        console.log("Error", err);
        swal("Error", err?.response?.data?.message, "error");
      });
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="auth-banner">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col lg={7}>
            <div className="auth-box" style={{ textAlign: "center" }}>
              <div style={{ textAlign: "left" }}>
                <FaArrowLeftLong
                  onClick={() => navigate('/forget-password')}
                  style={{ fontSize: "22px" , cursor:'pointer' }}
                />
              </div>
              <h2 className="auth-heading">You're Almost Finished</h2>
              <p className="auth-p">
                You only have to enter the OTP code that we have sent to your
                email address.
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <VerificationInput
                  length={4}
                  placeholder="_"
                  validChars="0-9"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(val) => {
                    setCode(val);
                  }}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "0",
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Button
                  className={`${code?.length !== 4 ? "mainbtn" : "mainbtn"}`}
                  style={{
                    cursor: "pointer",
                    marginTop: "20px",
                    paddingRight: "60px",
                    paddingLeft: "60px",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                  disabled={code?.length !== 4}
                >
                  CONFIRMATION
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ForgetPassword2;
