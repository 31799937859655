import React, { useState } from "react";
import { Col, Row, Image, Form, Input, Button, theme } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaPhoneVolume, FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { Post } from "../../config/api/post";

import { ImageUrl } from "../../config/helper";
import { FEEDBACK } from "../../config/constants/api";

// const { Meta } = Card;
const { TextArea } = Input;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const ContactUs = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.user.userToken);
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const { id } = useParams();


  const onFinish = (values) => {
    // Handle form submission
    setLoading(true);
    const { firstName, lastName, email, message, subject } = values;
    let body = {
      firstName,
      lastName,
      email,
      message,
      subject,
      type : 'USER'
    };
    if(userData){
      body.user = userData?._id
    } 
    Post(FEEDBACK.addFeedback, body , userToken ).then((response)=>{
      if(response?.status){
        setLoading(false);
        form.resetFields()
        handleChange()
      }
    }).catch((err)=>{
      let message = err?.response?.data?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
    })
  };
  const { TextArea } = Input;
  const handleChange = () => {
    swal("system Alert", "Your Message has been Send", "success");
  };
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  return (
    <div className="shop-page contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={22} md={22}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <h5 className="text-center">contact us</h5>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={22} lg={22}>
                  <div className="details-card my-4">
                    <Row gutter={[16, 16]}>
                      <Col xl={9} lg={10}>
                        <div className="help-frame">
                          <div>
                            <h6>Contact Information</h6>
                            <span>Event Mela</span>
                            <p>
                              <FaPhoneVolume /> +1 (269) 888-8866
                            </p>
                            <p>
                              <MdEmail /> Info@eventmela.com
                            </p>
                            <p>
                              <FaLocationDot /> 132 Dartmouth Street, Boston,
                              Massachusetts 02156, United States
                            </p>
                          </div>
                          <div className="social-icons">
                            <Link className="socialicon">
                              <Image
                                preview={false}
                                src={ImageUrl("fb-icon.png")}
                              />
                            </Link>
                            <Link className="socialicon">
                              <Image
                                preview={false}
                                src={ImageUrl("insta-icon.png")}
                              />
                            </Link>
                            <Link className="socialicon">
                              <Image
                                preview={false}
                                src={ImageUrl("snapchat-icon.png")}
                              />
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col xl={15} lg={14}>
                        <Form
                          className="row g-3"
                          name="basic"
                          layout="vertical"
                          initialValues={{
                            remember: true,
                          }}
                          // onFinish={onFinish}
                          autoComplete="off"
                          form={form}
                          onFinish={onFinish}
                        >
                        
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your First Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter First Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Last Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Last Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Email!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Email address"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Subject!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Subject"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Message"
                                name="message"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Message!",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Write your message.."
                                  className="web-textarea"
                                />
                              </Form.Item>
                            </Col>
                            <div className="" style={{ textAlign: "center" }}>
                              <Button
                                type=""
                                htmlType="submit"
                                className="mainbtn"
                              > 
                                {loading ? "Loading..." : "Send Feedback"}
                                <BsArrowUpRightCircleFill />
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
