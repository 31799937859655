import React, { useEffect, useState } from "react";
import { Col, Row, Menu, Image, Button, Popover, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import WOW from "wowjs";
import "animate.css";
import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../../redux/slice/authSlice";
const items = [
  {
    label: "Home",
    key: "/",
  },
  {
    label: "About us",
    key: "/about",
  },
  {
    label: "Events",
    key: "/events",
  },
  {
    label: "Blog",
    key: "/blog",
  },
  {
    label: "Contact Us",
    key: "/contactus",
  },
  {
    label: "Organize",
    key: "/organize",
  },
];

function Header({ header }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [current, setCurrent] = useState("mail");
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.user.userToken);

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 0, // default
      mobile: false, // default
      live: true, // default
    });
    wow.init();
  }, []);
  const handleLogout = () => {
    dispatch(removeUser());
    navigate("/login");
  };
  const content = (
    <div>
      <Button className="profile_btn" onClick={() => navigate("/profile")}>
        Profile
      </Button>
      <Button className="profile_btn" onClick={handleLogout}>
        logout
      </Button>
    </div>
  );
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onClick = (e) => {
    console.log("click ", e);
    navigate(e.key);
    setCurrent(e.key);
  };
  return (
    <Row
      justify={"center"}
      className="navigation wow fadeInDown"
      data-wow-duration="2.5s"
      data-wow-delay="0.8s"
    >
      <Col xs={22} lg={20}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={12} sm={12} lg={2} xl={2}>
            <Image
              className="logo"
              preview={false}
              src={ImageUrl("eventlogo.png")}  
              alt="logo"
            />
          </Col>
          <Col xs={0} sm={0} md={0} lg={15} xl={16} justify="center">
            <Menu
              className="mainMenu"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </Col>
          <Col xs={12} sm={12} lg={7} xl={6} justify={"end"}>
            <Row style={{ width: "100%", padding: "0px 50px" }}></Row>

            <Row align={"middle"}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={0}
                style={{ textAlign: "right" }}
              >
                <MenuOutlined
                  style={{ fontSize: "25px" }}
                  onClick={() => showDrawer()}
                />
              </Col>
              <Col lg={24} md={0} sm={0} xs={0}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {token && (
                    <Popover content={content}>
                      <Button className="profile">
                        <Image
                          className="logo"
                          preview={false}
                          src={ImageUrl("eventlogo.png")}
                          alt="banner"
                        />
                        {/* <Image
                          className="logo"
                          preview={false}
                          src={ImageUrl("logo.png")}
                          alt="banner"
                        /> */}
                      </Button>
                    </Popover>
                  )}
                  {!token && (
                    <Link to="/login">
                      <Button
                        className="mainbtn"
                        onClick={() => navigate("/login")}
                      >
                        LOGIN <BsArrowUpRightCircleFill />
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>
            {/* */}

            <Drawer
              placement={"left"}
              closable={true}
              onClose={onClose}
              open={open}
              key={"left"}
              className="menu-drawer"
            >
              <Menu
                className="mainMenu "
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={items}
              />
              <div
                className="mobileprofile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}
              >
                <Popover content={content}>
                  <Button className="profile">
                    <Image
                      className="logo"
                      preview={false}
                      src={ImageUrl("logo.png")}
                      alt="banner"
                    />
                  </Button>
                </Popover>
                {!token && (
                  <Link to="/login">
                    <Button
                      className="mainbtn"
                      onClick={() => navigate("/login")}
                    >
                      LOGIN <BsArrowUpRightCircleFill />
                    </Button>
                  </Link>
                )}
              </div>
            </Drawer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;
