import React from 'react'
import { Col, Row,} from "antd";

function AboutUs() {
  return (
    <div className='contentarea inner-page'>
        <Row justify={"center"}>
                <Col xs={22} lg={20}>
                    <h5 className='text-center wow fadeInDown'  data-wow-duration="2.5s" data-wow-delay="0.8s">About Us</h5>
                    <div className='entertainmentbottom mt-50 wow fadeInDown'  data-wow-duration="2.5s" data-wow-delay="0.8s">
                        <h6>Exclusive events, priceless memories</h6>
                        <h5>The Perfect  Venue For Your Next Event</h5>
                        <p>Welcome to Event Mela, your premier platform for discovering, organizing, and enjoying events with unparalleled ease. Our mission is to bridge the gap between event organizers and attendees, creating a seamless experience that makes event planning and ticket purchasing as effortless as possible.</p>
                        {/* <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letterset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galook. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                      <p>Event Mela is dedicated to fostering a vibrant community of event enthusiasts and creators. We are committed to providing a secure and reliable platform where organizers can reach a wider audience, and attendees can discover events that match their interests. Our team continually works to improve and innovate, ensuring that our platform remains at the forefront of event management technology.
  </p>
                    </div>
                </Col>
        </Row>
    </div>
  )
}

export default AboutUs