import React from 'react'
import { Col, Row,} from "antd";
import TesimonialsCaurosel from '../tesimonialsCaurosel';



function Tesimonials() {
  return (
    <div className="testimonials  wow fadeInDown" data-wow-duration="2.5s" data-wow-delay="0.8s">
        <Row justify={"center"}>
            <Col xs={22} lg={20}>
                <h3>Special Quote From Our Customer</h3>
                <h2>Testimonials</h2>
            </Col>
        </Row>
        <TesimonialsCaurosel/>
    </div>
  )
}

export default Tesimonials