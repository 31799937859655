import React from 'react'
import { Col, Row,  Button,} from "antd";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

function GetTickets() {
  const navigate = useNavigate()
  return (
    <Row justify={"center"}>
        <Col xs={22} lg={20}>
            <div className="gettickets wow fadeInDown" data-wow-duration="2.5s" data-wow-delay="0.8s">
                <h3>GET TICKETS NOW!</h3>
                <p>Don't miss out the fun and craziness. get your ticket now</p>
                <Button className='mainbtn' onClick={()=>{navigate('/events')}} >GET STARTED <BsArrowUpRightCircleFill /></Button>
            </div>
        </Col>
    </Row>
    
  )
}

export default GetTickets