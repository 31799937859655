import React from "react";
import AliceCarousel from "react-alice-carousel";
import { Col, Row, Image, Avatar } from "antd";
import { ImageUrl } from "../../config/helper";

const responsive = {
  380: { items: 2 },
  568: { items: 2 },
  1024: { items: 3 },
  1200: { items: 4 },
  1400: { items: 4 },
  1500: { items: 5 },
};
const items = [
  <div className="item">
    <div className="topCategeorySlider">
      <Image preview={false} src={ImageUrl("all-icon.png")} />
      <span>All</span>
    </div>
  </div>,
  <div className="item">
    <div className="topCategeorySlider">
      <Image preview={false} src={ImageUrl("attractions-icon.png")} />
      <span>Attractions</span>
    </div>
  </div>,
  <div className="item">
    <div className="topCategeorySlider">
      <Image preview={false} src={ImageUrl("playground-icon.png")} />
      <span>Playground</span>
    </div>
  </div>,
  <div className="item">
    <div className="topCategeorySlider">
      <Image preview={false} src={ImageUrl("entertainment.png")} />
      <span>Entertainment</span>
    </div>
  </div>,
  <div className="item">
    <div className="topCategeorySlider">
      <Image preview={false} src={ImageUrl("events.png")} />
      <span>Events</span>
    </div>
  </div>,
];

function CaregeorySlider() {
  return (
    <div className="categeorySlider">
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            disableButtonsControls={false}
            disableDotsControls
            controlsStrategy="alternate"
            paddingLeft={0}
            paddingRight={0}
            infinite
            autoPlay={false}
        />
            </Col>
        </Row>
      
    </div>
  );
}

export default CaregeorySlider;
