import React, { useEffect } from "react";
import { Col, Row, Card, Image, List } from "antd";
import { ImageUrl, extractDate } from "../../config/helper";
import { CiCalendar } from "react-icons/ci";
import { IoIosPhonePortrait } from "react-icons/io";
import { FaCheckCircle, FaHeadphones } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UPLOADS_URL } from "../../config/constants/api";

const data = [
  {
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];

function TicketIssue() {
  const location = useLocation();
  const { eventDetails, booking, qrCode } = location?.state;
  const navigate = useNavigate();
  useEffect(() => {
    if (!eventDetails || !booking) {
      navigate(-1);
    }
  }, []);
  return (
    <div className="contentarea inner-page">
      <Row justify={"center"}>
        <Col xs={22} lg={20}>
          <Row>
            <Col xs={24}>
              <Card className="blogCard" bordered={false}>
                <h3>Your E-Ticket Has Been Issued</h3>
                <p>
                  Please download the E-ticket from your inbox or email spam
                  folder at Putraadi@gmail.com.
                  <br />
                  Hope your activities there are enjoyable!
                </p>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={22} lg={14}>
              <Card className="blogCard" bordered={false}>
                <h4>
                  <span>Order Number</span>
                  <span>{booking?._id}</span>
                  {/* <span>2106423742</span> */}
                  <span>
                    <Link>
                      E-TICKETS HAVE BEEN ISSUE <FaCheckCircle />
                    </Link>
                  </span>
                </h4>
                {/* <h4>Bali Zoo Tickets</h4> */}
                <h4>{eventDetails?.title}</h4>
                <Row gutter={16} justify={"center"} align={"middle"}>
                  <Col xs={24} md={5} lg={5}>
                    <Image
                      className="ticketimage"
                      preview={false}
                      src={UPLOADS_URL + eventDetails?.image}
                    />
                  </Col>
                  {/* <Col xs={24} md={19} lg={19}><h4><span><CiCalendar /> Saturday, 08 April 2023</span></h4> */}
                  <Col xs={24} md={19} lg={19}>
                    <h4>
                      <span>
                        <CiCalendar />
                        {extractDate(eventDetails?.date)}
                      </span>
                    </h4>
                    <h4>
                      <span> Allowed Guest</span> <span>Adult</span>
                    </h4>
                  </Col>
                </Row>
              </Card>
              <Card className="blogCard tickets-sectionbg" bordered={false}>
                <h4>
                  <span>
                    <IoIosPhonePortrait /> Thank you for tickets through
                    Ticketed!
                  </span>
                </h4>
                <List
                  itemLayout="horizontal"
                  dataSource={eventDetails?.venueDetails}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Image
                            preview={false}
                            src={ImageUrl("checklist.png")}
                          />
                        }
                        title={item}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col xs={22} lg={10}>
              <Card className="blogCard" bordered={false}>
                <h4>
                  <span>
                    <Link>
                      E-TICKETS HAVE BEEN ISSUE <FaCheckCircle />
                    </Link>
                  </span>
                </h4>
                <hr className="smallhr mt-15 mb-15" />

                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-15 mt-15"
                >
                  <Col>
                    <span className="visitoinfo">Payment status</span>
                  </Col>
                  <Col>
                    <span className="visitoinfo">Already paid</span>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-15"
                >
                  <Col>
                    <span className="visitoinfo">Total</span>
                  </Col>
                  <Col>
                    <span className="visitoinfo">
                      USD {booking.totalAmount}
                    </span>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-15 mt-15"
                >
                  <Col>
                    <span className="visitoinfo">Payment method</span>
                  </Col>
                  <Col>
                    <span className="visitoinfo">Bank transfer</span>
                  </Col>
                </Row>
                <hr className="smallhr mb-15" />
                <p>
                  SSL Secured, all transactions at Ticketed are protected and
                  security is guaranteed 100%
                </p>
              </Card>
              <Card className="blogCard" bordered={false}>
                {/* <h4><span><FaHeadphones /> Butuh Bantuan?</span></h4> */}
                <div>
                  <h2 style={{ color: "#FFF" }}>Your QR Code:</h2>
                  <img
                    src={qrCode}
                    alt="QR Code"
                    style={{
                      height: "300px",
                      objectFit: "contain",
                      objectPosition: "left",
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default TicketIssue;
