import React, { useEffect, useState } from "react";
import { Col, Row, Card, Image, List, Spin, Button } from "antd";
import SelectTickets from "../../components/selectTickets";
import TicketsDetailsSlider from "../../components/ticketDetailsSlider";
import { FaLocationDot } from "react-icons/fa6";
import { TbBuildingCarousel, TbTicket } from "react-icons/tb";
import { GiForkKnifeSpoon, GiWashingMachine } from "react-icons/gi";
import { FaRegSnowflake } from "react-icons/fa";
import { ImageUrl, extractDate } from "../../config/helper";
import { Get } from "../../config/api/get";
import { EVENT, UPLOADS_URL } from "../../config/constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
import { extractTime } from "../../config/helpers";

const data = ["Indonesian", "English"];
function TicketsDetails() {
  const token = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getEventDetails();
  }, []);
  const getEventDetails = () => {
    setLoading(true);
    Get(`${EVENT.getEvent}${id}`, token)
      .then((response) => {
        if (response?.status) {
          setEventDetails(response?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching event details ", err);
        setLoading(false);
      });
  };
  const formatTime = (time24) => {
    if (!time24) return "";

    const [hour, minute, second] = time24.split(":");
    const date = new Date();
    date.setHours(hour, minute, second);

    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  return (
    <>
      <div className="contentarea inner-page tickets-sectionbg">
        <Row>
          {!loading ? (
            eventDetails && (
              <Col xs={22}>
                <Row justify={"center"}>
                  <Col xs={22} lg={20}>
                    <Card className="blogCard" bordered={false}>
                      <h3>{eventDetails?.title}</h3>
                      <h4>
                        <span>
                          <FaLocationDot /> {eventDetails?.location?.address}
                        </span>{" "}
                        <span>
                          <TbBuildingCarousel /> {eventDetails?.type}
                        </span>
                      </h4>
                      <Image
                        preview={false}
                        src={UPLOADS_URL + eventDetails?.image}
                        className="ticketDetailsBanner"
                      />
                    </Card>
                  </Col>
                </Row>

                <Row justify={"center"} gutter={[16, 16]}>
                  <Col xs={22} lg={13}>
                    <Card className="blogCard" bordered={false}>
                      <h3>Venue Details</h3>
                      <h5>Highlights</h5>

                      <List
                        itemLayout="horizontal"
                        dataSource={eventDetails?.venueDetails}
                        renderItem={(item, index) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Image
                                  preview={false}
                                  src={ImageUrl("checklist.png")}
                                />
                              }
                              title={item}
                            />
                          </List.Item>
                        )}
                      />

                      <h3>Description</h3>
                      <p>{eventDetails?.description}</p>
                      <TicketsDetailsSlider
                        image={eventDetails?.image}
                        gallery={eventDetails?.gallery}
                      />
                    </Card>
                  </Col>
                  <Col xs={22} lg={7}>
                    <Card className="blogCard" bordered={false}>
                      <h3>Facility</h3>
                      {eventDetails?.facilities &&
                        eventDetails?.facilities.map((item, index) => {
                          return (
                            <span key={index} className="facilityTag">
                              {item[0].toUpperCase() + item.slice(1)}
                            </span>
                          );
                        })}
                      {/* <span className="facilityTag">
                      <FaRegSnowflake /> Air Conditioner
                    </span>
                    <span className="facilityTag">
                      <GiWashingMachine /> Washer
                    </span> */}
                    </Card>
                    {/* <SelectTickets /> */}
                    <div className="selectTickets">
                      <h3>
                        <span>Starting from</span>{" "}
                        <span>
                          <TbTicket />
                        </span>
                      </h3>
                      {/* <h5>USD 140.000</h5> */}
                      <h2>USD {eventDetails?.price}.00</h2>
                      <h4>
                        {/* <CiCalendar /> Available Today */}
                        <CiCalendar /> Event Date :{" "}
                        {extractDate(eventDetails?.date)}
                      </h4>
                      <hr />
                      <h4>Opening hours</h4>
                      {/* <h2>Mon- Sunday , 09.00 - 17.00</h2> */}
                      <h2>{extractTime(eventDetails?.time)}</h2>
                      <Button
                        onClick={() => {
                          navigate(`/order_details/${eventDetails?._id}`, {
                            state: { eventDetails },
                          });
                        }}
                        className="web-btn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Buy Ticket Now <BsArrowUpRightCircleFill />
                      </Button>
                      {/* <h4>Opening hours</h4>
                      <List
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      /> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            )
          ) : (
            <Col
              lg={19}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "150px 0px",
              }}
            >
              <Spin />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}

export default TicketsDetails;
