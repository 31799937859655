import React from "react";
import { Col, Row, Button } from "antd";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SocialEntertainment() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  return (
    <div>
      <Row justify={"center"}>
        <Col xs={22} lg={20}>
          <div
            className="entertainmenttopbg  wow fadeInDown"
            data-wow-duration="2.5s"
            data-wow-delay="0.8s"
          >
            <div className="entertainmenttopbg-inner">
              <h3>
                Social
                <br />
                Entertainment
                <br />
                Venue
              </h3>
              <p>
                Get ready to get playful, and create memories around the table{" "}
              </p>
              <Button
                className="mainbtn"
                onClick={() => {
                  navigate("/events");
                }}
              >
                GET A TICKET <BsArrowUpRightCircleFill />
              </Button>
            </div>
          </div>
          <div
            className="entertainmentbottom  wow fadeInDown"
            data-wow-duration="2.5s"
            data-wow-delay="1s"
          >
            <h6>Exclusive events, priceless memories</h6>
            <h5>The Perfect Venue For Your Next Event</h5>
            <p>
              We believe that attending events should be a joyous and
              straightforward experience. With Event Mela, purchasing tickets is
              incredibly user-friendly, allowing you to buy tickets with just
              one click. Our platform is designed to provide a smooth and
              intuitive experience, from browsing events to finalizing your
              purchase. No more long queues or complicated processes – simply
              find the event you’re interested in and secure your spot
              effortlessly.
            </p>

            <p>
              Join the Event Mela community today and be part of a dynamic
              ecosystem where events come to life. Whether you’re an organizer
              looking to make your mark or an attendee eager to explore new
              experiences, Event Mela is here to make your journey as enjoyable
              and stress-free as possible. Discover the joy of events with Event
              Mela – where every event is just a click away.
            </p>
            <p>
              At Event Mela, we are committed to making event management and
              attendance hassle-free. Our user-friendly interface ensures a
              smooth experience for both organizers and attendees, making every
              event memorable and accessible. Join us and be part of the Event
              Mela community, where every event is just a click away.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {!token && (
                <Button
                  className="mainbtn"
                  onClick={() => {
                    navigate("/createAccount");
                  }}
                >
                  JOIN US <BsArrowUpRightCircleFill />
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SocialEntertainment;
