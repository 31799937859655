import React from 'react'
import { TbTicket } from "react-icons/tb";
import { Button, Input} from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { CiCalendar } from "react-icons/ci";
import { List,} from 'antd';
import { BsArrowUpRightCircleFill } from 'react-icons/bs';
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);
// const onSearch = (value, _e, info) => console.log(info?.source, value);

const data = [
    'Indonesian',
    'English',
  ];

function SelectTickets() {
  return (
    <div className='selectTickets'>
        <h3><span>Starting from</span> <span><TbTicket /></span></h3>
        {/* <h5>USD 140.000</h5> */}
        <h2>USD 126.000</h2>
        <Button className="mainbtn">
                Book Now <BsArrowUpRightCircleFill />
              </Button>
    <h4><CiCalendar /> Available Today</h4>
    <hr/>
    <h4>Opening hours</h4>
    <h2>Mon- Sunday , 09.00 - 17.00</h2>
    <h4>Opening hours</h4>
    <List
      dataSource={data}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
    </div>
  )
}

export default SelectTickets