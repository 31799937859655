import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Col, Row, Image } from "antd";
import { ImageUrl } from "../../config/helper";
import { UPLOADS_URL } from "../../config/constants/api";

function TicketsDetailsSlider({ image, gallery }) {
  const [allImages, setAllImages] = useState(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setAllImages([image, ...gallery]);
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, [sliderRef1?.current, sliderRef2?.current, allImages]);
  return (
    <Row justify={"center"}>
      <Col xs={22}>
        {gallery?.length ? (
          <div className="slider-container">
            <Slider asNavFor={nav2} ref={(slider) => (sliderRef1 = slider)}>
              {Array.isArray(allImages) &&
                allImages.map((image, index) => {
                  return (
                    <div key={index}>
                      <span>
                        <img
                          src={UPLOADS_URL + image}
                          alt=""
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
            </Slider>

            <Slider
              asNavFor={nav1}
              ref={(slider) => (sliderRef2 = slider)}
              slidesToShow={gallery?.length}
              swipeToSlide={true}
              focusOnSelect={true}
            >
              {Array.isArray(allImages) &&
                allImages.map((image, index) => {
                  return (
                    <div key={index} className="slider-nav">
                      <span>
                        <img
                          src={UPLOADS_URL + image}
                          alt=""
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "90px",
                            objectFit: "cover",
                            objectPosition: "center",
                            maxWidth: "100%",
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
            </Slider>
          </div>
        ) : (
          <div className="slider-container">
            <img
              src={UPLOADS_URL + image}
              alt=""
              className="img-fluid"
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </div>
        )}
      </Col>
    </Row>
  );
}

export default TicketsDetailsSlider;
