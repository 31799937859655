import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import Banner from "../../components/banner";
import AccordionTabs from "../../components/accordion";
import MarqueeText from "../../components/marquee";
import SocialEntertainment from "../../components/socialEntertainment";
import Trending from "../../components/trending";
import Testimonials from "../../components/testimonials";
import GetTickets from "../../components/getTickets";
import BlogCards from "../../components/blogCard";
import { BLOG } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useSelector } from "react-redux";
import { ImageUrl } from "../../config/helper";
function Home() {
  const token = useSelector((state) => state.user.userToken);
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const getBlogs = (pageNumber, pageSize) => {
    setLoading(true);
    Get(BLOG.getAllBlogs, token, {
      page: "1",
      limit: "4",
    })
      .then((response) => {
        if (response?.status) {
          setBlogs(response?.data?.docs);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getBlogs();
  }, []);
  const [blogCard, SetBlogCard] = useState([
    {
      key: 1,
      image: ImageUrl("blog-1.png"),
      title: "6 Strategies  Your Conference Keynote ",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },

    {
      key: 2,
      image: ImageUrl("blog-2.png"),
      title: "How successfully used paid ticket sales",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },
    {
      key: 3,
      image: ImageUrl("blog-3.png"),
      title: "Introducing work with new navigation",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },
    {
      key: 4,
      image: ImageUrl("blog-4.png"),
      title: "6 Strategies  Your Conference Keynote",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },
  ]);
  return (
    <div>
      <Banner />
      <MarqueeText />
      <div className="accordion">
        <AccordionTabs />
      </div>
      <div className="contentarea">
        <SocialEntertainment />
        <Trending />
        <Testimonials />
        {/* <Tesimonials/> */}

        <div className="blog">
          <Row justify={"center"}>
            <Col xs={22} lg={20}>
              <h3>Be sure not to miss these event today.</h3>
              <h2>BLOG POST</h2>

              {blogs && (
                <Row justify={"center"} gutter={16}>
                  {blogs.length > 0 &&
                    blogs.map((item, index) => {
                      return (
                        <Col xs={22} md={12} lg={6}>
                          <BlogCards item={item} />{" "}
                        </Col>
                      );
                    })}
                </Row>
              )}
            </Col>
          </Row>
        </div>
        <GetTickets />
      </div>
    </div>
  );
}

export default Home;
