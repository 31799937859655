import React, { useEffect, useState } from "react";
import { Col, Row, Image, Spin } from "antd";
import { ImageUrl } from "../../config/helper";
import { Get } from "../../config/api/get";
import { EVENT, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Trending() {
  const [trendingEvents, setTrendingEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  useEffect(() => {
    getTrendingEvents();
  }, []);
  const getTrendingEvents = () => {
    setLoading(true);
    Get(EVENT.getTrendingEvents, token)
      .then((response) => {
        if (response?.status) {
          setTrendingEvents(response?.data);
          console.log(response)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching trending Events");
        setLoading(false);
      });
  };
  return (
    <div
      className="trending  wow fadeInDown"
      data-wow-duration="2.5s"
      data-wow-delay="0.8s"
    >
      <Row justify={"center"}>
        {!loading ? (
          <Col xs={22} lg={20}>
            <h3>Sit back while we create the experience of a lifetime</h3>
            <h2>TRENDING EVENTS</h2>
            {Array.isArray(trendingEvents) && trendingEvents.length && (
              <Row gutter={[32, 32]}>
                {trendingEvents.map((event, index) => {
                  return (
                    <Col
                      key={index}
                      xs={24}
                      md={12}
                      lg={12}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/event-details/${event?._id}`);
                      }}
                    >
                      <div
                        className="trend wow fadeInLeft"
                        data-wow-duration="2.5s"
                        data-wow-delay="0.8s"
                      >
                        <h4>{event?.title}</h4>
                        <Image
                          preview={false}
                          src={UPLOADS_URL + event?.image}
                        />
                        <h5>{event?.title}</h5>
                      </div>
                    </Col>
                  );
                })}
                {/* <Col xs={24} md={12} lg={12}>
                    <div className="trend wow fadeInRight" data-wow-duration="2.5s" data-wow-delay="0.8s">
                            <h4>Music Event</h4>
                            <Image  preview={false}  src={ImageUrl("trending-event-2.png")} />
                            <h5>Music Event</h5>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                    <div className="trend wow fadeInLeft" data-wow-duration="2.5s" data-wow-delay="0.8s">
                            <h4>COMEDY NIGHT</h4>
                            <Image  preview={false}  src={ImageUrl("trending-event-3.png")} />
                            <h5>COMEDY NIGHT</h5>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                    <div className="trend wow fadeInRight" data-wow-duration="2.5s" data-wow-delay="0.8s">
                            <h4>Dussehra Event</h4>
                            <Image  preview={false}  src={ImageUrl("trending-event-4.png")} />
                            <h5>Dussehra Event</h5>
                        </div>
                    </Col> */}
              </Row>
            )}
          </Col>
        ) : (
          <Row
            style={{
              width: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Col
              lg={19}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "150px 0px",
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
}

export default React.memo(Trending);
