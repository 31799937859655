import React from 'react'
import Marquee from "react-fast-marquee";

function MarqueeText() {
  return (
    <div className="marqueetext  wow fadeInDown" data-wow-duration="2.5s" data-wow-delay="0.8s">
        <Marquee loop={0} speed={50}>
      <span style={{color:'#fff'}}>Reach your dream + Get a Ticket +</span>
      <span style={{color:'#fff'}}>Reach your dream + Get a Ticket +</span>
      <span style={{color:'#fff'}}>Reach your dream + Get a Ticket +</span>
      <span style={{color:'#fff'}}>Reach your dream + Get a Ticket +</span>
      <span style={{color:'#fff'}}>Reach your dream + Get a Ticket +</span>
      <span style={{color:'#fff'}}>Reach your dream + Get a Ticket +</span>
      <span style={{color:'#fff'}}>Reach your dream + Get a Ticket +</span>
    </Marquee>
    </div>
  )
}

export default MarqueeText