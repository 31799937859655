import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Radio,
  Table,
  Select,
  Form,
  Input,
  InputNumber,
  Button,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import QuantitySelector from "../../components/quantitySelector";
import { IoMdPerson } from "react-icons/io";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { CiCalendar, CiDollar } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
import { ImageUrl, extractDate } from "../../config/helper";
import { UPLOADS_URL } from "../../config/constants/api";

function OrderDetails() {
  const [form] = Form.useForm();
  const location = useLocation();
  const eventDetails = location?.state?.eventDetails;
  const [quantity, setQuantity] = useState(1)
  const [totalPrice, setTotalPrice] = useState(eventDetails?.price*quantity);
  const [editIndex, setEditIndex] = useState(null);
  const [editedVisitor, setEditedVisitor] = useState({});
  const data = useSelector((state) => state.user.userData);
  const [visitors, setVisitors] = useState([data]);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const calculateTotalPrice = (visitors) => {
    return visitors.reduce(
      (total, visitor) => total + visitor.quantity * visitor.unitPrice,
      0
    );
  };
useEffect(()=>{
  if(!eventDetails){
    navigate(-1)
  }
})
  const [orders, setOrders] = useState([
    {
      _id: 1,
      ticket: "Adikarya's son",
      quantity: 10,
      unitPrice: "180",
      totalPrice: 100,
    },
    {
      _id: 2,

      ticket: "Adikarya's son",
      quantity: 10,
      unitPrice: "180",
      totalPrice: 100,
    },
  ]);
  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditedVisitor(visitors[index]);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedVisitor({
      ...editedVisitor,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    const updatedVisitors = visitors.map((visitor, index) =>
      index === editIndex ? editedVisitor : visitor
    );
    setVisitors(updatedVisitors);
    setEditIndex(null);
  };
  useEffect(() => {
    getOrders();
  }, []);
  const handleSelectedQuantityChange = (value) => {
    // let _visitors = [...visitors];
    // _visitors[index].quantity = value;
    // setOrders(_visitors);
    // Calculate total price
    setQuantity(value)
    // const newTotalPrice = calculateTotalPrice(_visitors);
    setTotalPrice(eventDetails?.price*value);
  };
  const getOrders = async (pageNumber, pageSize, search, reset = false) => {
    //   setLoading(true);
    try {
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const columns = [
    {
      title: "ticket",
      dataIndex: "",
      key: "fullName",
      render: (item) => (
        <span>
          {item?.firstName ? item.firstName : ""} {item?.lastName ? item.lastName : ""}
        </span>
      ),
    }
,    
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
      render: (item, value) => (
        <QuantitySelector
          value={quantity}
          onChange={(e) => handleSelectedQuantityChange(e)}
        />
      ),
    },

    {
      title: "UNIT PRICE",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (item) => <span>${eventDetails?.price}</span>,
    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (item, value) => <span>${totalPrice}</span>,
    },
  ];
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  // Radio button
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const onChangeNumber = (value) => {
    console.log("changed", value);
  };
  // const onFinish = (values) => {
  //   const { fullName, title, email, phone, specialRequest } = values;
  //   const newVisitor = {
  //     fullName,
  //     title,
  //     email,
  //     phone,
  //     specialRequest,
  //     quantity: 1,
  //     unitPrice: eventDetails?.price,
  //   };
  //   const updatedVisitors = [...visitors, newVisitor];
  //   setVisitors(updatedVisitors);

  //   // Calculate total price
  //   const newTotalPrice = calculateTotalPrice(updatedVisitors);
  //   setTotalPrice(newTotalPrice);
  //   form.resetFields();
  // };
  return (
    <div className="contentarea inner-page">
      <Row justify={"center"}>
        <Col xs={22} lg={20}>
          <Row>
            <Col xs={24}>
              <Card className="blogCard" bordered={false}>
                <h3>Your booking</h3>
                <p>Fill in the data and review your order</p>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={22} lg={14}>
              {/* {visitors.length > 0 &&
                visitors.map((visitor, index) => ( */}
                  <Card className="blogCard" bordered={false}>
                    <h3>Orderer Details</h3>
                    {/* {editIndex === index ? (
                      <>
                        <Row gutter={[16, 16]}>
                          <Col xs={24} lg={8}>
                            <h5>Full Name</h5>
                            <Input
                              name="fullName"
                              value={editedVisitor.fullName}
                              onChange={handleInputChange}
                            />
                          </Col>
                          <Col xs={24} lg={8}>
                            <h5>Cell Phone Number</h5>
                            <Input
                              name="phone"
                              value={editedVisitor.phone}
                              onChange={handleInputChange}
                            />
                          </Col>
                          <Col xs={24} lg={8}>
                            <h5>Email</h5>
                            <Input
                              name="email"
                              value={editedVisitor.email}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                        <Button
                          type="primary"
                          onClick={handleSaveClick}
                          style={{ marginTop: "8px" }}
                        >
                          Save Changes
                        </Button>
                      </>
                    ) : ( */}
                      <>
                        <h4>
                          <span>{data?.firstName + ' ' + data?.lastName}</span>
                          {/* <span>
                            <Link onClick={() => handleEditClick(index)}>
                              Change details
                            </Link>
                          </span> */}
                        </h4>
                        <hr className="my-25" />
                        <Row>
                          <Col xs={24} lg={8}>
                            <h5>Cell Phone Number</h5>
                            <h5>{data?.phone}</h5>
                          </Col>
                          <Col xs={24} lg={10}>
                            <h5>Email</h5>
                            <h5>{data?.email}</h5>
                          </Col>
                        </Row>
                      </>
                    {/* )} */}
                  </Card>
                // )
                {/* )} */}
              {/* }) } */}
              {/* <hr className="mt-50  mb-50" /> */}
              {/* <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>I ordered for me</Radio>
                <Radio value={2}>I ordered it for someone else</Radio>
              </Radio.Group> */}

              <Card className="blogCard" bordered={false}>
                <h3>Number of visitors</h3>

                <Row style={{ padding: 20, overflow: "auto" }}>
                  <Table
                    className="styledTable2"
                    // dataSource={orders}
                    dataSource={visitors}
                    columns={columns}
                    pagination={false}
                  />
                </Row>
              </Card>

              {/* <Card className="blogCard" bordered={false}>
                <Form
                  className="row g-3"
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  // onFinish={onFinish}
                  autoComplete="off"
                  form={form}
                  onFinish={onFinish}
                >
                  <h3>Visitor Details</h3>
                  <h4>
                    <span>
                      <IoMdPerson /> Visitor 1
                    </span>
                    <span>
                      <Button
                        style={{ background: "none", color: "#fff" }}
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </span>
                  </h4>
                  <hr className="my-25" />

                  <Row>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "Please enter title!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Title"
                          className="orderDetailsFiled"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Full name"
                        name="fullName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Your Full name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Full name"
                          className="orderDetailsFiled"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Your Email!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email"
                          className="orderDetailsFiled"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Phone number"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Your Phone number!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Number"
                          className="orderDetailsFiled"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={24}>
                      <Form.Item
                        label="Special Request (Optional)"
                        name="specialRequest"
                        rules={[
                          {
                            required: false,
                            message: "Please input!",
                          },
                        ]}
                      >
                        <Input.TextArea className="text-area-order" />
                      </Form.Item>

                      <p>Requests depend on operator availability</p>
                    </Col>
                  </Row>
                </Form>
              </Card> */}
              <Card className="blogCard" bordered={false}>
                <h3 className="mb-25">Number of visitors</h3>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-25"
                >
                  <Col>
                    <span className="visitoinfo">The price you pay</span>
                  </Col>
                  <Col xs={0} md={17}>
                    <hr className="smallhr" />
                  </Col>
                  <Col>
                    <span className="visitoinfo">${totalPrice}.00</span>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-25"
                >
                  <Col>
                    <span className="visitoinfo">Tax%</span>
                  </Col>
                  <Col xs={0} md={19}>
                    <hr className="smallhr" />
                  </Col>
                  <Col>
                    <span className="visitoinfo">$00</span>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-25"
                >
                  <Col xs={24}>
                    <hr className="smallhr mb-15" />
                  </Col>
                  <Col>
                    <span className="visitoinfo">Total amount payable</span>
                  </Col>

                  <Col>
                    <span className="visitoinfo">${totalPrice}.00</span>
                  </Col>
                  <Col xs={24}>
                    <hr className="smallhr  mt-15" />
                  </Col>
                </Row>
              </Card>
              <div className="" style={{ textAlign: "center" }}>
                <Button
                  onClick={() => navigate("/payment", {state : {total : totalPrice , quantity , eventDetails  }})}
                  type=""
                  htmlType="Continue to Payment"
                  className="mainbtn"
                >
                  {loading ? "Loading..." : "Continue to Payment"}
                  <BsArrowUpRightCircleFill />
                </Button>
              </div>
            </Col>
            <Col xs={22} lg={10}>
              <Card className="blogCard" bordered={false}>
                <h3>Order Summary</h3>
                <hr className="smallhr mt-15 mb-15" />
                <h4>{eventDetails?.title}</h4>
                <Row gutter={16} justify={"center"} align={"middle"}>
                  <Col xs={24} md={5} lg={5}>
                    <Image
                      className="ticketimage"
                      preview={false}
                      src={UPLOADS_URL + eventDetails?.image}
                    />
                  </Col>
                  <Col xs={24} md={19} lg={19}>
                    <p>
                      {eventDetails?.description.length > 70
                        ? eventDetails?.description.substring(0, 70) + "..."
                        : eventDetails?.description}
                    </p>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-15 mt-15"
                >
                  <Col>
                    <span className="visitoinfo">The price you pay</span>
                  </Col>
                  <Col xs={0} md={14} lg={9} xl={10}>
                    <hr className="smallhr" />
                  </Col>
                  <Col>
                    <span className="visitoinfo">${totalPrice}.00</span>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-25"
                >
                  <Col>
                    <span className="visitoinfo">Tax%</span>
                  </Col>
                  <Col xs={0} md={19} lg={14}>
                    <hr className="smallhr" />
                  </Col>
                  <Col>
                    <span className="visitoinfo">$00</span>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="mb-25"
                >
                  <Col xs={24}>
                    <hr className="smallhr mb-15" />
                  </Col>
                  <Col>
                    <span className="visitoinfo">Total amount payable</span>
                  </Col>

                  <Col>
                    <span className="visitoinfo">${totalPrice}.00</span>
                  </Col>
                  <Col xs={24}>
                    <hr className="smallhr  mt-15" />
                  </Col>
                </Row>
              </Card>
              <Card className="blogCard" bordered={false}>
                <h4>
                  <span>
                    <CiCalendar /> Effective {extractDate(eventDetails?.date)}
                  </span>
                </h4>
                <h4>
                  <span>
                    <FaPhoneAlt /> No reservation required
                  </span>
                </h4>
                <h4>
                  <span>
                    <CiDollar /> Easy refund
                  </span>
                </h4>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default OrderDetails;
